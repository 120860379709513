import useForm from './useForm.js';
import React, { useState, useCallback, useEffect } from "react";
import validate from "../../utils/util-validator";
import ruleSet from './ruleSet.js';
import { navigate, Link } from "gatsby";
import Loadable from "@loadable/component";
import Anchor from "@components/content/anchor.jsx"

// Style Imports
import "./Form.scss"
// Utility Imports
import { getVisitorId, getApiEndpoint} from '@src/helper';

// Content Imports
import ToolTip from '@components/content/tooltip';
const ToolTipIcon = Loadable(() => import('@components/content/icons/ToolTip.jsx'))

const SignupForm = () => {
    const [showDoctorContainer, setShowDoctorContainer] = useState(false);
    const [showMailContainer, setShowMailContainer] = useState(false);

    const success = useCallback((vals) => {
        const submitButton = document.querySelector("button[type='submit']");
        submitButton.classList.add('button--submitting');
        fetch(getApiEndpoint() + 'consumer/signup', {
            method: 'POST',
            mode: 'cors',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(vals),
        })
        .then(async (resp) => {
            var r = await resp.json();
            submitButton.classList.remove('button--submitting');
            if (r.success) {
                gtmFormSuccess()
                gtmFormFieldCompletionSubmitted()
                navigate('/registration-confirmed',
                {
                    state: {
                        success: true
                    }
                });
            } else {
                navigate('/500');
            }
        })
        .catch((err) => {
            submitButton.classList.remove('button--submitting');
            navigate('/500');
        })
    }, [])



    const prospectOnChange = (e) =>{
        const prospectID = e.currentTarget.id;
        if(prospectID === 'newlyDiagnosedProspect' || prospectID === 'experiencedProspect' || prospectID === 'caregiverProspect'){
            setShowDoctorContainer(true);
        }
        else{
            setShowDoctorContainer(false);
        }

        handleChange(e);
    }

    const mailCheckboxOnClick = (e) =>{
        const checked = e.currentTarget.checked;
        if(checked){
            setShowMailContainer(true);
        }
        else{
            setShowMailContainer(false);
        }
        handleChange(e);
    }

    //GTM


    const gtmFormSuccess = () => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event' : 'Form Submission',
            'Event_Category': 'Form Submissions',
            'Event_Action': 'Signup',
            'Event_Label': 'Complete'
        });
    }
    const gtmFormFieldError = (errors) => {
        if ( errors ) {
            for(var error in errors){
                const selector = ".error--" + error;
                if(document.querySelector(selector)){
                    const errorText = document.querySelector(selector).innerText;
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event' : 'Form Errors',
                        'Event_Category': 'Validation Errors',
                        'Event_Action': 'Signup',
                        'Event_Label': error + '_' + errorText
                    });
                }

            }
        }
    }

    const gtmFieldCompletion = () => {
        document.querySelectorAll('.form--signup input, .form--signup select').forEach( field => {
            if(!field.getAttribute('data-has-ga-listener')){
                field.setAttribute('data-has-ga-listener','true')
                field.addEventListener('change', fieldCompletionEvent);
            }
        });
    }

    const fieldCompletionEvent = function(e) {
        const field = e.target;
        let fieldInfo;
        switch(field.type){
            case ('radio'):
                fieldInfo = (field.getAttribute('gaVal')) ? (field.name + '_' + field.getAttribute('gaVal')) : 'No gaVal found';
                break;
            case ('checkbox'):
                fieldInfo = field.name + '_' + field.checked;
                break;
            default:
                fieldInfo = field.name
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event' : 'Field Completion',
            'Event_Category': 'Form Field Completion',
            'Event_Action': 'Signup',
            'Event_Label': fieldInfo
        });
    }

    const gtmFormFieldCompletionSubmitted = () => {
        document.querySelectorAll('.form--signup input, .form--signup select').forEach( field => {
            let fieldInfo;

            if ((field.type === 'radio' || field.type === 'checkbox') && !field.checked) return;
            if (field.value.length === 0) return;

            switch(field.type){
                case ('radio'):
                    fieldInfo = (field.getAttribute('gaVal')) ? (field.name + '_' + field.getAttribute('gaVal')) : 'No gaVal found';
                    break;
                case ('checkbox'):
                    fieldInfo = field.name + '_' + field.checked;
                    break;
                default:
                    fieldInfo = field.name
            }
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event' : 'Form Field Completion_Form Submitted',
                'Event_Category': 'Form Field Completion_Form Submitted',
                'Event_Action': 'Signup',
                'Event_Label': fieldInfo
            });
        })
    }

    useEffect(()=>{
        gtmFieldCompletion();
    })

    const {
        values,
        errors,
        handleChange,
        handleSubmit,
      } = useForm(success, validate, ruleSet.signup.rules, {source: 'lupkynis_consumer_stay_connected_2021', visitorId : getVisitorId(), optin: true}, ()=>{}, gtmFormFieldError );
    return (
            <form className="form--signup" onSubmit={handleSubmit}>
                <div className="radio-buttons radio-buttons--prospect" role='radiogroup' aria-labelledby="prospect-legend">
                    <p id="prospect-legend" className="legend">I am*</p>
                    <div className="radio-wrapper">
                        <input name="prospect" id="newlyDiagnosedProspect" type="radio" gaVal="1" value="Newly Diagnosed Prospect" checked={values.prospect === "Newly Diagnosed Prospect"} onChange={(e)=>{prospectOnChange(e); handleChange(e)}} aria-invalid={errors.prospect ? true : false} />
                        <label htmlFor="newlyDiagnosedProspect">Newly diagnosed with lupus nephritis in the last 6&nbsp;months</label>
                    </div>
                    <div className="radio-wrapper">
                        <input name="prospect" id="experiencedProspect" type="radio" gaVal="2" value="Experienced Prospect" checked={values.prospect === "Experienced Prospect"} onChange={(e)=>{prospectOnChange(e); handleChange(e)}} aria-invalid={errors.prospect ? true : false} />
                        <label htmlFor="experiencedProspect">Diagnosed with lupus nephritis more than 6&nbsp;months ago</label>
                    </div>
                    <div className="radio-wrapper">
                        <input name="prospect" id="unknownProspect" type="radio" gaVal="3" value="Undiagnosed with LN Prospect [Unknown]" checked={values.prospect === "Undiagnosed with LN Prospect [Unknown]"} onChange={(e)=>{prospectOnChange(e); handleChange(e)}} aria-invalid={errors.prospect ? true : false} />
                        <label htmlFor="unknownProspect">Diagnosed with lupus, but not lupus nephritis</label>
                    </div>
                    <div className="radio-wrapper">
                        <input name="prospect" id="caregiverProspect" type="radio" gaVal="4" value="Caregiver Prospect" checked={values.prospect === "Caregiver Prospect"} onChange={(e)=>{prospectOnChange(e); handleChange(e)}} aria-invalid={errors.prospect ? true : false} />
                        <label htmlFor="caregiverProspect">Caring for someone with lupus/lupus nephritis</label>
                    </div>
                    <div className="radio-wrapper">
                        <input name="prospect" id="interestedProspect" type="radio" gaVal="5" value="Interested Prospect" checked={values.prospect === "Interested Prospect"} onChange={(e)=>{prospectOnChange(e); handleChange(e)}} aria-invalid={errors.prospect ? true : false} />
                        <label htmlFor="interestedProspect">Just interested in learning about LUPKYNIS</label>
                    </div>
                    {errors.prospect && (
                        <p className="error error--margin-top error--prospect">Please select the statement that best describes you or the person you are representing</p>
                    )}
                </div>

                {showDoctorContainer? [
                    <div className="radio-buttons radio-buttons--prescribed" role='radiogroup' aria-labelledby="doctorPrescribed-legend">
                        <p id="doctorPrescribed-legend" className="legend">A doctor has prescribed LUPKYNIS for me or someone I care for.*</p>
                        <div className="radio-wrapper">
                            <input name="doctorPrescribed" id="doctorPrescribed" type="radio" gaVal="1" value="prescribed" checked={values.doctorPrescribed === "prescribed"} onChange={handleChange} aria-invalid={errors.doctorPrescribed ? true : false} />
                            <label htmlFor="doctorPrescribed">Yes</label>
                        </div>
                        <div className="radio-wrapper">
                            <input name="doctorPrescribed" id="notDoctorPrescribed" type="radio" gaVal="2" value="notPrescribed" checked={values.doctorPrescribed === "notPrescribed"} onChange={handleChange} aria-invalid={errors.doctorPrescribed ? true : false} />
                            <label htmlFor="notDoctorPrescribed">No</label>
                        </div>
                        {errors.doctorPrescribed && showDoctorContainer &&(
                            <p className="error error--doctorPrescribed">Please select</p>
                        )}
                    </div>
                ] : '' }

                <div className="single-col-input">
                    <label>
                        First Name*:
                        <input type="text" name="firstName" id="firstName" value={values.firstName || ''} onChange={handleChange} aria-invalid={errors.firstName ? true : false} placeholder="First name" maxLength={'50'}/>
                    </label>
                    {errors.firstName && (
                        <p className="error error--firstName">Please enter your first name</p>
                    )}
                    <label>
                        Last Name*:
                        <input type="text" name="lastName" id="lastName" value={values.lastName || ''} onChange={handleChange} aria-invalid={errors.lastName ? true : false} placeholder="Last name" maxLength={'50'}/>
                    </label>
                    {errors.lastName && (
                        <p className="error error--lastName">Please enter your last name</p>
                    )}
                    <label>
                        Email Address*:
                        <input type="text" name="email" id="email" placeholder="e.g. jsmith@email.com" onChange={handleChange} aria-invalid={errors.email ? true : false} maxLength={'50'}/>
                    </label>
                    {errors.email && (
                        <p className="error error--email">Please enter a valid email address</p>
                    )}
                </div>
                <div className="radio-buttons radio-buttons--call" role='radiogroup' aria-labelledby="receiveCall-legend">
                    <p id="receiveCall-legend" className="legend kf-tooltip-positioner">Would you like to be contacted by a Nurse Case Manager to hear about Aurinia Alliance support offerings?*</p>
                    <ToolTip ID={"SignupTooltip"} ariaLabel={"Toggle Aurinia Alliance Tool Tip"} logo={<ToolTipIcon />}>Aurinia Alliance is a personalized program designed to support you throughout your LUPKYNIS treatment journey</ToolTip>
                    <div className="radio-wrapper">
                        <input name="receiveCall" id="receiveCallYes" type="radio" gaVal="1" value="receiveCallYes" checked={values.receiveCall === "receiveCallYes"} onChange={handleChange} aria-invalid={errors.receiveCall ? true : false} />
                        <label htmlFor="receiveCallYes">Yes</label>
                    </div>
                    <div className="radio-wrapper">
                        <input name="receiveCall" id="receiveCallNo" type="radio" gaVal="2" value="receiveCallNo" checked={values.receiveCall === "receiveCallNo"} onChange={handleChange} aria-invalid={errors.receiveCall ? true : false} />
                        <label htmlFor="receiveCallNo">No</label>
                    </div>
                </div>
                {errors.receiveCall &&(
                    <p className="error error--receiveCall">Please select</p>
                )}
                {values.receiveCall === "receiveCallYes"? [
                    <div className="phone-info">
                        <div className="single-col-input">
                            <label>
                                Phone Number*:
                                <input type="text" name="phoneNumber" id="phoneNumber" value={values.phoneNumber || ''} placeholder="Phone number" onChange={handleChange} aria-invalid={errors.phoneNumber ? true : false} maxLength={'14'}/>
                            </label>
                            {errors.phoneNumber && (
                                <p className="error error--phoneNumber">Please input a valid phone number (i.e.&nbsp;(555)&nbsp;555&#8209;5555)</p>
                            )}
                            </div>
                            <p className="disclaimer">Aurinia Alliance will contact you from this number <span className="nowrap">1-833-AURINIA (1-833-287-4642)</span></p>
                            <div className="single-col-input">

                            <label>
                                ZIP Code*:
                                <input type="text" name="zipCodePhone" id="zipCodePhone" value={values.zipCodePhone || ''} placeholder="ZIP code" onChange={handleChange} aria-invalid={errors.zipCodePhone ? true : false} maxLength={'5'}/>
                            </label>
                            {errors.zipCodePhone && (
                                <p className="error error--zipCodePhone">Please input a valid ZIP code (i.e.&nbsp;12345)</p>
                            )}
                        </div>
                            <p className="disclaimer">Your ZIP code enables us to connect you with the Nurse Case Manager in your area</p>
                    </div>

                ] : '' }
                <div className="checkbox-wrapper">
                    <input name="receiveMail" id="receiveMailCheckbox" type="checkbox" checked={values.receiveMail}  onChange={mailCheckboxOnClick} />
                    <label htmlFor="receiveMailCheckbox">I would like to receive information by mail (optional)</label>
                </div>
                {showMailContainer? (
                    <div className="single-col-input address-input">
                        <label>
                            Street Address 1*
                            <input type="text" name="streetAddress" id="streetAddress" value={values.streetAddress || ''} placeholder="Street address 1" onChange={handleChange} aria-invalid={errors.streetAddress ? true : false} maxLength={'50'}/>
                        </label>
                        {errors.streetAddress && (
                            <p className="error error--streetAddress">Please enter your address</p>
                        )}
                        <label>
                            Street Address 2 (optional)
                            <input type="text" name="streetAddress2" id="streetAddress2" value={values.streetAddress2 || ''} placeholder="Street address 2" onChange={handleChange} maxLength={'50'}/>
                        </label>

                        <label>
                            City*
                            <input type="text" name="city" id="city" value={values.city || ''} onChange={handleChange} placeholder="City" aria-invalid={errors.city ? true : false} maxLength={'50'}/>
                        </label>
                        {errors.city && (
                            <p className="error error--city">Please input your city</p>
                        )}

                        <label className="short-input">
                            State*
                            <select name="usState" id="usState" value={values.usState || ''} onChange={handleChange} aria-invalid={errors.usState ? true : false}>
                                    <option value="">State</option>
                                    <option value="AL">Alabama</option>
                                    <option value="AK">Alaska</option>
                                    <option value="AZ">Arizona</option>
                                    <option value="AR">Arkansas</option>
                                    <option value="CA">California</option>
                                    <option value="CO">Colorado</option>
                                    <option value="CT">Connecticut</option>
                                    <option value="DE">Delaware</option>
                                    <option value="DC">District Of Columbia</option>
                                    <option value="FL">Florida</option>
                                    <option value="GA">Georgia</option>
                                    <option value="HI">Hawaii</option>
                                    <option value="ID">Idaho</option>
                                    <option value="IL">Illinois</option>
                                    <option value="IN">Indiana</option>
                                    <option value="IA">Iowa</option>
                                    <option value="KS">Kansas</option>
                                    <option value="KY">Kentucky</option>
                                    <option value="LA">Louisiana</option>
                                    <option value="ME">Maine</option>
                                    <option value="MD">Maryland</option>
                                    <option value="MA">Massachusetts</option>
                                    <option value="MI">Michigan</option>
                                    <option value="MN">Minnesota</option>
                                    <option value="MS">Mississippi</option>
                                    <option value="MO">Missouri</option>
                                    <option value="MT">Montana</option>
                                    <option value="NE">Nebraska</option>
                                    <option value="NV">Nevada</option>
                                    <option value="NH">New Hampshire</option>
                                    <option value="NJ">New Jersey</option>
                                    <option value="NM">New Mexico</option>
                                    <option value="NY">New York</option>
                                    <option value="NC">North Carolina</option>
                                    <option value="ND">North Dakota</option>
                                    <option value="OH">Ohio</option>
                                    <option value="OK">Oklahoma</option>
                                    <option value="OR">Oregon</option>
                                    <option value="PA">Pennsylvania</option>
                                    <option value="RI">Rhode Island</option>
                                    <option value="SC">South Carolina</option>
                                    <option value="SD">South Dakota</option>
                                    <option value="TN">Tennessee</option>
                                    <option value="TX">Texas</option>
                                    <option value="UT">Utah</option>
                                    <option value="VT">Vermont</option>
                                    <option value="VA">Virginia</option>
                                    <option value="WA">Washington</option>
                                    <option value="WV">West Virginia</option>
                                    <option value="WI">Wisconsin</option>
                                    <option value="WY">Wyoming</option>
                            </select>
                        </label>
                        {errors.usState && (
                            <p className="error error--usState">Please input your state</p>
                        )}
                        <label className="short-input">
                            ZIP Code*
                            <input type="text" name="zipCodeAddress" id="zipCodeAddress" value={values.zipCodeAddress || ''} placeholder="ZIP code" onChange={handleChange} aria-invalid={errors.zipCodeAddress ? true : false} maxLength={'5'}/>
                        </label>
                        {errors.zipCodeAddress && (
                            <p className="error error--zipCodeAddress">Please input a valid ZIP code (i.e.&nbsp;12345)</p>
                        )}

                    </div>
                ) : ''}
                <div className="agreement"><p>By clicking submit below, you agree that the information you voluntarily provide may be used by Aurinia Pharma U.S., Inc. or other companies acting on its behalf to provide you with information about educational materials, resources, market research, approved treatment options, and programs/services about lupus nephritis. Aurinia will handle your personal information in accordance with Aurinia's <Anchor url="https://www.auriniapharma.com/privacy-statement" target="_blank">Privacy Statement</Anchor>. For additional information, please read our Privacy Statement. You may <Link to="/unsubscribe">unsubscribe</Link> from our list at any time.</p></div>
                <div className="checkbox-wrapper">
                    <input name="ageResident" id="ageResident" type="checkbox" checked={values.ageResident} onChange={handleChange} aria-invalid={errors.ageResident ? true : false} />
                    <label htmlFor="ageResident">By checking this box I certify that I am 18 years of age or older and I reside in the United States.*</label>
                </div>
                {errors.ageResident && (
                        <p className="error error--ageResident">Please confirm</p>
                    )}
                <button type="submit" className="button button--signup">Sign up</button>
            </form>
    )
}

export default SignupForm;
