import React from "react"
// Style Imports
import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"
import SignUpForm from "@components/form/Form.Signup.jsx"

const registration = () => (
    <Layout addedClass="page--registration">
        <Seo title="Registration | LUPKYNIS&reg; (voclosporin)" description="Sign up for the latest about LUPKYNIS, get information about living with lupus nephritis, and hear about Aurinia Alliance®, a personalized support program. See full Safety and Prescribing Information, and BOXED WARNING." canonicalURL="https://www.lupkynis.com/registration" />
        <Hero addedClass="bg-coral hero--string">
            <div className="col-wrapper">
                <div className="container">
                    <div className="hero__content">
                        <h1 className="heading heading--accent">Registration</h1>
                        <h2>Stay connected</h2>
                        <p>Sign up for the latest about LUPKYNIS, get information about living with lupus nephritis, and hear about Aurinia Alliance<sup>&reg;</sup>, a personalized support program.</p>
                    </div>
                    <div className="hero__image">
                        <Image data={{desktopSrc: 'registration/hero.png', mobileSrc: 'registration/hero--mobile.png'}} />
                    </div>
                </div>
            </div>
        </Hero>
        <section id="SignUp" className="bg-purple">
            <div className="container">
                <h2>Tell us about yourself</h2>
                <p className="legend">* = required field</p>
                <SignUpForm />
            </div>
        </section>

    </Layout>
)

export default registration